<template>
  <div>
    <div class="flex flex-row pb-3">
      <div class="w-1/2">
        <el-input
          v-model="querySearch"
          placeholder="Buscar por nombre, apellido o correo electrónico">
          <i slot="prefix" class='mt-2 text-xl bx bx-search' />
        </el-input>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full border-collapse">
        <thead>
          <tr class="px-2 border-b-2">
            <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"></th>
            <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
              Empresa
            </th>
            <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
              Persona de contact
            </th>
            <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
              Email
            </th>
            <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
              Teléfono
            </th>
            <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
              Sitio Web
            </th>
            <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
              Descripción
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!suppliers.length">
            <td :colspan="8">
              <div class="flex flex-col w-full py-8 text-center">
                <span>{{ emptyMessage }}</span>
              </div>
            </td>
          </tr>
          <tr
            v-for="supplier in suppliers"
            :key="supplier.id"
            class="cursor-pointer hover:bg-gray-100 hover:shadow">
            <td class="p-2 border-t" style="min-width: 50px; width: 50px;">
              <el-popover
                placement="right"
                width="100"
                trigger="hover">
                <div class="w-full flex flex-row text-center">
                  <div class="w-1/2">
                    <button
                      title="Editar"
                      class="el-button--primary rounded-full cursor-pointer"
                      @click="onEdit(supplier)">
                      <i class="bx bx-edit" style="padding: 4px; font-size: 20px;"></i>
                    </button>
                  </div>
                  <div class="w-1/2">
                    <button
                      title="Eliminar"
                      class="el-button--danger rounded-full cursor-pointer"
                      @click="onDelete(supplier)">
                      <i class="bx bx-trash" style="padding: 4px; font-size: 20px;"></i>
                    </button>
                  </div>
                </div>
                <el-button slot="reference" type="text" class="w-full">
                  <button
                    class="el-button--primary rounded-full cursor-pointer">
                    <i class="text-2xl bx bx-chevron-right"></i>
                  </button>
                </el-button>
              </el-popover>
            </td>
            <td class="p-2 border-t">
              {{ supplier.company }}
            </td>
            <td class="p-2 border-t">
              {{ supplier.contactFullName }}
            </td>
            <td class="p-2 border-t">
              {{ supplier.contactEmail }}
            </td>
            <td class="p-2 border-t">
              {{ supplier.contactPhone }}
            </td>
            <td class="p-2 border-t">
              {{ supplier.webpage }}
            </td>
            <td class="p-2 border-t">
              {{ supplier.contactDescription }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import SupplierService from '@/services/SupplierService';
import error from '@/mixins/error';

export default {
  name: 'SuppliersList',
  mixins: [
    error
  ],
  data () {
    return {
      querySearch: null,
      suppliers: [],
      loadingSuppliers: false,
    };
  },
  computed: {
    emptyMessage() {
      return this.querySearch !== null
        ? `No se encontraron proveedores que coincidan con "${this.querySearch}"`
        : 'No se han agregado proveedores'
    },
  },
  watch: {
    querySearch: _.debounce(async function (querySearch) {
      this.suppliers = await this.loadSuppliers(querySearch)
    }, 500),
  },
  async mounted() {
    this.suppliers = await this.loadSuppliers()
  },
  methods: {
    async loadSuppliers(querySearch) {
      try {
        return await SupplierService.getAll(querySearch)
      }
      catch(error) {
        this.$toastr.e(this.getErrorMessage(error))
      }
    },
    onEdit(supplierToEdit) {
      this.$emit("onEdit", supplierToEdit);
    },
    onDelete(supplier) {
      this.$confirm(`¿Está seguro que desea eliminar al proveedor ${supplier.contactFullName}?`, {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
      }).then(async () => {
        await this.remove(supplier);
      })
      .catch((e) => {
        console.log('el catch que no sabemos que hace', e);
      });
    },
    async remove(supplier) {
      try {
        await SupplierService.remove(supplier.id);
        const indexOfCurrentSupplier = this.suppliers.findIndex(s => s.id === supplier.id);
        this.suppliers.splice(indexOfCurrentSupplier, 1);
        this.$toastr.e('Se borro con exito');
      }
      catch {
        this.$toastr.e('Ocurrió un error al eliminar el proveedor');
      }
    },
  },
};
</script>
